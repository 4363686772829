<template>
  <b-card>
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="items"
      :rtl="direction"
      :total="total"
      :max-height="maxHeight"
      :pagination-options="{
        enabled: true,
        perPage: filter.per_page,
      }"
      :select-options="filter.tab=='overdue'?selectOptions: { enabled: false}"
      @on-column-filter="onColumnFilter"
      @on-selected-rows-change="selectionChanged"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <slot
          name="table-column"
          :row="props.row"
          :column="props.column"
          :index="props.index"
        />
        <span v-if="props.column.field === 'row_number'">
          {{ getRowNumber(props.index) }}
        </span>

        <span v-if="props.column.isAmount">
          {{ props.row[props.column.field] | formatPrice }}
        </span>
        <span v-else-if="props.column.field === 'overdue_amount'">
          <b-badge
            v-if="props.formattedRow.overdue_amount > 0"
            pill
            variant="light-danger"
          >{{ props.formattedRow.overdue_amount | formatPrice }}</b-badge>
          <span v-else>{{ props.formattedRow.overdue_amount }}</span>
        </span>
        <span v-else-if="props.column.showField && hasPartyShow">
          <span
            v-if="props.row.hired_worker_user_id"
            class="link"
            @click="goTo(model + '-show-hworker', { id: props.row.id })"
          >{{ props.formattedRow[props.column.field] }}</span>
          <span
            v-else
            class="link"
            @click="goTo(model + '-show', { id: props.row.id })"
          >{{ props.formattedRow[props.column.field] }}</span>
        </span>
        <span v-else-if="props.column.showField && hasShow">
          <span
            class="link"
            @click="goTo(model + '-show', { id: props.row.id })"
          >{{ props.formattedRow[props.column.field] }}</span>
        </span>
        <span v-else-if="props.column.showField && hasContractShow">
          <span
            class="link"
            @click="goTo(model + '-show', { id: props.row.contract_id })"
          >{{ props.formattedRow[props.column.field] }}</span>
        </span>
        <span v-else-if="props.column.field === 'confirm_action'">
          <b-button
            v-if="
              (props.row.status=='uploaded') ||(props.column.isOrders && props.row.images_confirmation_status == 0)
            "
            type="submit"
            variant="primary"
            class="mb-2"
            @click="$emit('confirm', props.row.id)"
          >
            Tasdiqlash
          </b-button>
          <b-button
            v-else-if="props.column.isTransfer"
            type="submit"
            variant="primary"
            class="mb-2"
            @click="$emit('confirm', props.row.id)"
          >
            Tasdiqlash
          </b-button>
        </span>
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <template v-if="props.column.isContract">
                <b-dropdown-item
                  v-if="hasEdit"
                  @click="goTo('OrderCreate', _, { pin: props.row.pin })"
                >
                  <feather-icon
                    icon="FilePlusIcon"
                    class="mr-50"
                  />
                  <span>{{ $t("Создать договор") }}</span>
                </b-dropdown-item>
              </template>
              <template v-else-if="props.column.isOrders">
                <b-dropdown-item
                  v-if="hasEdit"
                  @click="
                    $router.push({
                      name: model + '-show',
                      params: { id: props.row.id },
                    })
                  "
                >
                  <feather-icon
                    icon="FilePlusIcon"
                    class="mr-50"
                  />
                  <span>{{ $t("Создать оплата") }}</span>
                </b-dropdown-item>
              </template>
              <template v-else>
                <b-dropdown-item
                  v-if="hasEdit"
                  @click="$emit('edit', props.row)"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Tahrirlash</span>
                </b-dropdown-item>
                <template v-if="props.column.isBlackList">
                  <b-dropdown-item
                    @click="$emit('blacklistOut', { pin: props.row.pin })"
                  >
                    <feather-icon
                      icon="UploadIcon"
                      class="mr-50"
                    />
                    <span>Qora ro'yhatdan chiqarish</span>
                  </b-dropdown-item>
                </template>
                <b-dropdown-item
                  v-if="hasDelete"
                  @click="$emit('delete', props.row.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>O'chirish</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="props.column.hasActionSlot"
                  @click="$emit('slotAction', props.row)"
                >
                  <slot
                    name="slot-action"
                    :row="props.row"
                    :column="props.column"
                    :index="props.index"
                  />
                </b-dropdown-item>
              </template>
            </b-dropdown>
          </span>
        </span>
        <span v-else-if="props.column.field === 'status'">
          <template v-if="props.column.isUserList">
            <b-badge
              v-if="props.row.status === 1"
              pill
              variant="light-success"
            >{{
              $t("Активный")
            }}</b-badge>
            <b-badge
              v-if="props.row.status === 0"
              pill
              variant="light-warning"
            >{{
              $t("Нективный")
            }}</b-badge>
          </template>
          <b-badge
            v-else
            pill
            variant="light-info"
          >{{
            props.formattedRow[props.column.field]
          }}</b-badge>
        </span>
        <span v-else-if="props.column.field === 'status_name'">
          <b-badge
            pill
            variant="light-warning"
          >{{
            props.row.status_name
          }}</b-badge>
        </span>
        <span v-else-if="props.column.field === 'court_status'">
          <div v-if="props.row.status == 2">
            <b-badge
              pill
              variant="light-success"
            > Yopilgan </b-badge>
          </div>
          <b-badge
            v-else
            pill
            variant="light-warning"
          >
            <span v-if="props.row.status == 1 && props.row.court_decision_url">
              Qaror kuchga kirgan
            </span>
            <span
              v-else-if="props.row.status == 1 && props.row.court_letter_url"
            >
              Xat berilgan
            </span>
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'attachStatus'">
          <b-badge
            v-if="props.row.contract_product_id"
            pill
            variant="light-success"
          >Biriktirilgan</b-badge>
          <b-badge
            v-else
            pill
            variant="light-warning"
          >Biriktirilmagan</b-badge>
        </span>
        <span v-else-if="props.column.field === 'decition_summa'">
          {{
            ((props.row.penalty_amount ? Number(props.row.penalty_amount) : 0) +
              (props.row.overdue_amount_at_decision
                ? Number(props.row.overdue_amount_at_decision)
                : 0))
              | formatPrice
          }}
        </span>
        <span v-else-if="props.column.field === 'decition_payed_summa'">
          {{
            ((props.row.payment_amount_this_month
              ? Number(props.row.payment_amount_this_month)
              : 0) +
              (props.row.payment_amount_until_this_month
                ? Number(props.row.payment_amount_until_this_month)
                : 0))
              | formatPrice
          }}
        </span>
        <span
          v-else-if="props.column.isDangerAmount"
          class="text-danger"
        >
          {{ props.row[props.column.field] | formatPrice }}
        </span>
        <span
          v-else-if="props.column.isSuccessAmount"
          class="text-success"
        >
          {{ props.row[props.column.field] | formatPrice }}
        </span>
        <span v-else-if="props.column.isDate">
          {{ dateDotFormat(props.row[props.column.field]) }}
        </span>
        <span v-else-if="props.column.isDateTime">
          {{ formatDateYmd(props.row[props.column.field]) }}
        </span>
        <span v-else-if="props.column.isTooltip">
          <!-- {{ props.index }}/{{ showAllComment[props.index] }}
          <span
            :class="{'text-ellipsis':!showAllComment[props.index] }"
            @click="showAllComment[props.index] = true"
          >
            {{ props.row.comment }}
          </span> -->
          <span
            :class="{ 'text-ellipsis': !showFullComment[props.index] }"
            @click="
              showFullComment[props.index] = !showFullComment[props.index]
            "
          >
            {{ props.row.comment }}
          </span>
          <!-- <b-button id="tooltip-target-1">
            {{ props.row.comment }}
          </b-button>
          <b-tooltip target="tooltip-target-1" triggers="hover">
            {{ props.row.comment }}
          </b-tooltip> -->
        </span>
        <span v-else-if="props.column.field === 'all_debt_amount'">
          {{
            (Number(props.row.credit_amount) -
              Number(props.row.all_payment_amount))
              | formatPrice
          }}
        </span>
        <span v-else-if="props.column.isUrl">
          <a
            v-if="props.formattedRow[props.column.field]"
            :href="storageUrl + props.formattedRow[props.column.field]"
            class="link"
            target="_blank"
          >Asos hujjat</a>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Маълумотлар
              {{ Number(page - 1) * Number(filter.per_page) + 1 }} дан
              {{ Number(page) * Number(filter.per_page) }} гача
              <b>{{ total }}</b>
            </span>
            <b-form-select
              v-model="filter.per_page"
              :options="['10', '20', '50', '100', '200','500']"
              class="mx-1"
              @input="(value) => (filter.per_page = value)"
            />
            <!-- <span class="text-nowrap "> Саҳифадаги маълумотлар</span> -->
          </div>
          <div>
            <b-pagination
              :value="page"
              :total-rows="total"
              :per-page="filter.per_page"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="onPageChange"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
      <div slot="emptystate">
        <div class="w-100 text-center">
          {{ $t("Маълумот йўқ") }}
        </div>
      </div>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BPagination,
  BFormSelect,
  BCard,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import store from '@/store/index'

export default {
  components: {
    BCard,
    BLink,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BButton,
    BDropdown,
    BDropdownItem,
  },
  props: {
    columns: {
      type: Array,
      default() {
        return []
      },
    },
    items: {
      type: Array,
      default() {
        return []
      },
    },
    filter: {
      type: Object,
      default() {
        return {}
      },
    },
    total: {
      type: Number,
      default() {
        return 0
      },
    },
    page: {
      type: Number,
      default() {
        return 1
      },
    },
    modelName: {
      type: String,
      default() {
        return ''
      },
    },
    model: {
      type: String,
      default() {
        return ''
      },
    },
    hasShow: {
      type: Boolean,
      default() {
        return false
      },
    },
    hasPartyShow: {
      type: Boolean,
      default() {
        return false
      },
    },
    hasContractShow: {
      type: Boolean,
      default() {
        return false
      },
    },
    hasEdit: {
      type: Boolean,
      default() {
        return true
      },
    },
    hasDelete: {
      type: Boolean,
      default() {
        return true
      },
    },
    maxHeight: {
      type: String,
      default() {
        return ''
      },
    },
  },
  data() {
    return {
      dir: false,
      selectOptions: {
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
      },
      showFullComment: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        10: false,
        11: false,
        12: false,
        13: false,
        14: false,
        15: false,
        16: false,
        17: false,
        18: false,
        19: false,
        20: false,
        21: false,
        22: false,
        23: false,
        24: false,
        25: false,
        26: false,
        27: false,
        28: false,
        29: false,
        30: false,
        31: false,
        32: false,
        33: false,
        34: false,
        35: false,
        36: false,
        37: false,
        38: false,
        39: false,
        40: false,
        41: false,
        42: false,
        43: false,
        44: false,
        45: false,
        46: false,
        47: false,
        48: false,
        49: false,
        50: false,

      },
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    storageUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/`
    },
  },
  methods: {
    onColumnFilter(params) {
      this.columns
        .filter(item => item.filterOptions && item.filterOptions.enabled)
        .forEach(item => {
          let { field } = item
          if (typeof field === 'function') {
            field = item.filterField
          }
          if (item.filterOptions.filterDropdownItems) {
            const splitted = field.split('.')
            const newField = splitted.length === 1 ? splitted[0] : `${splitted[0]}_id`
            this.filter[newField] = params.columnFilters[field]
              ? params.columnFilters[field]
              : null
          } else {
            this.filter[field] = params.columnFilters[item.field]
              ? params.columnFilters[item.field]
              : null
          }
        })
      this.$emit('getItems')
    },
    onPageChange(page) {
      this.changeQuery('page', page)
      this.$emit('onPageChange', page)
    },
    getRowNumber(index) {
      return (this.page - 1) * this.filter.per_page + index + 1
    },
    toggleComment(id) {
      this.showFullComment[id] = false
    },
    selectionChanged(rows) {
      console.log(rows)
      this.$emit('selectedRows', rows.selectedRows)
    },
  },
}
</script>
