<template>
  <div>
    <save
      ref="saveForm"
      @onSuccess="getItems"
    />
    <oklad
      ref="okladForm"
      @onSuccess="getItems"
    />
    <b-overlay :show="loading">
      <good-table-column-search
        :items="items.data"
        :columns="columns"
        :total="items.total"
        :page="page"
        :filter="filterModel"
        @getItems="getItems"
        @add="$refs.saveForm.visible = true"
        @edit="(item) => $refs.saveForm.edit(item)"
        @delete="destroy"
        @slotAction="slotAction"
        @onPageChange="(p) => (page = p)"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          {{ props }}
        </template>
        <template
          slot="slot-action"
          slot-scope="props"
        >
          <feather-icon
            icon="DollarSignIcon"
            class="mr-50"
          />
          <span>Oklad</span>
        </template>
      </good-table-column-search>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showToast } from '@/utils/toast'
import GoodTableColumnSearch from '@/views/table/vue-good-table/GoodTableColumnSearch.vue'
import Save from './save.vue'
import Oklad from './oklad.vue'

export default {
  name: 'Index',
  components: {
    GoodTableColumnSearch, Save,Oklad
  },
  data() {
    return {
      page: 1,
      loading: false,
      roles: [],
      filterModel: {
        per_page: 50,
        number: null,
        address: null,
        region_id: null,
        relations: 'role',
        city_id: null,
        calculating_type_id: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      items: 'user/GET_ITEMS',
    }),
    columns() {
      return [
        {
          label: '#',
          field: 'row_number',
        },
        {
          label: this.$t('Имя'),
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Логин'),
          field: 'login',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Роль'),
          field: 'role.name',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('Все'),
          },
        },
        {
          label: this.$t('Статус'),
          field: 'status',
          isUserList: true,
          filterOptions: {
            enabled: true,
            placeholder: 'Все',
            filterDropdownItems: [
              { value: 1, text: this.$t('Активный') },
              { value: 0, text: this.$t('Неактивный') },
            ],
          },
        },
        {
          label: this.$t('Действия'),
          field: this.hasPermission('settings.users-update') ? 'action' : '',
          hasActionSlot: true,
        },
      ]
    },
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.per_page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
  },
  mounted() {
    if (!this.hasPermission('settings.users-index')) {
      this.$router.push('/')
    }
    this.getItems()
    this.fetchRoles()
      .then(res => {
        if (res.success) {
          // this.mapCols('role.name', res)
          this.columns.map(item => {
            if (item.field === 'role.name') {
              item.filterOptions.filterDropdownItems = res.data.data.map(i => {
                i.value = i.id
                i.text = i.name
                return i
              })
            }
            // if (item.field === 'status') {
            // item.filterOptions.filterDropdownItems = [
            //   { value: 1, text: this.$t('Активный') },
            //   { value: 0, text: this.$t('Неактивный') },
            // ]
            // }
          })
        }
      })
  },
  methods: {
    ...mapActions({
      getItemsAction: 'user/index',
      fetchRoles: 'resource/roles',
      destroyAction: 'user/destroy',
    }),
    slotAction(data) {
      console.log(666, data)
      this.$refs.okladForm.edit(data)
    },
    async getItems() {
      this.loading = true
      await this.getItemsAction({ ...this.filterModel, page: this.page })
      this.loading = false
    },
    nameByLocal(nameuz, nameru) {
      if (this.$i18n.locale == 'uz') { return nameuz }
      return nameru
    },
    destroy(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Вы действительно хотите удалить?'), {
          title: this.$t('Подтвердите'),
          size: 'sm',
          variant: 'warning',
          okVariant: 'primary',
          okTitle: this.$t('Да'),
          cancelTitle: this.$t('Нет'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.destroyAction(id).then(res => {
              showToast('success', this.$t('Успешно удалено'))
              this.getItems()
            }).catch(() => {
              showToast('success', this.$t('Успешно удалено'))
            })
          }
        })
    },
    mapCols(filterName, res) {
      this.columns.map(item => {
        if (item.field === filterName) {
          item.filterOptions.filterDropdownItems = res.data.data.map(i => {
            i.value = i.id
            i.text = i.name
            return i
          })
        }
        // if (item.field === 'status') {
        //   item.filterOptions.filterDropdownItems = [
        //     { value: 1, text: this.$t('Активный') },
        //     { value: 0, text: this.$t('Неактивный') },
        //   ]
        // }
      })
    },
  },
}
</script>

<style scoped></style>
